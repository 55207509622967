<!--
 * @Description: 名下设备
 * @Author: ChenXueLin
 * @Date: 2021-10-18 11:41:19
 * @LastEditTime: 2022-04-22 14:57:35
 * @LastEditors: LiangYiNing
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
        >
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { getEngineerEquip } from "@/api";
export default {
  name: "ownEquipment",
  components: {},
  data() {
    return {
      total: 0,
      searchForm: {
        pageIndex: 1,
        pageSize: 20
      },
      userCode: "",
      columnData: [
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipmentType",
          display: true,
          fieldLabel: "类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipNo",
          display: true,
          fieldLabel: "设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "collectionTime",
          display: true,
          fieldLabel: "领取时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  props: [],
  computed: {},
  watch: {},
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.userCode = hasParamsRoute.params.userCode;
    if (this.userCode) {
      this.queryList();
    }
  },
  methods: {
    //获取名下设备列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getEngineerEquip({
          ...this.searchForm,
          employeeNo: this.userCode
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.hasSel {
  padding: 20px;
  box-sizing: border-box;
  .num {
    color: #46bfea;
  }
}
.top-button {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}
.select-scene-content {
  display: flex;

  .tree {
    width: 180px;
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid #edf0f5;
    padding-bottom: 20px;
    .category-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      .category {
        width: 180px;
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        position: relative;
        font-size: 14px;
        cursor: pointer;
      }
      .active {
        background: #f2f5fa;
        color: #46bfea;
        &::before {
          content: " ";
          display: block;
          width: 3px;
          height: 100%;
          background: #46bfea;
          position: absolute;
          top: 0px;
          left: 0px;
        }
      }
    }
  }
  .table {
    width: calc(100% - 180px);
    height: 100%;
    padding: 10px;
    // /deep/.el-table {
    //   width: 600px;
    // }
    /deep/.el-input {
      width: 200px;
      margin-bottom: 10px;
    }
  }
}
</style>
